import {
    IEditUserDto,
    ISignUp,
    IUserLogin,
    IUserResponseToken,
    IUserWithDescription,
} from 'src/shared/interfaces';
import {
    ILoginWithoutPasswordDto,
    IUserRegisterWithOrganization,
} from 'src/shared/interfaces/api/authApi';
import { ITokens } from 'src/shared/interfaces/api/authApi/IUserResponseToken';
import { iamApi } from '../main';

interface IAccessTokens {
    data: Omit<ITokens, 'refreshToken'>;
}

export const authApi = iamApi.injectEndpoints({
    endpoints: (builder) => ({
        refresh: builder.mutation<IAccessTokens, void>({
            query: () => ({
                url: '/authentication/refresh-tokens',
                method: 'POST',
                credentials: 'include',
            }),
        }),
        login: builder.mutation<IUserResponseToken, IUserLogin>({
            query: (data) => ({
                url: '/authentication/sign-in',
                method: 'POST',
                body: data,
            }),
        }),
        loginWithoutPassword: builder.mutation<
            IUserResponseToken,
            ILoginWithoutPasswordDto
        >({
            query: (data) => ({
                url: '/authentication/sign-in-without-password',
                method: 'POST',
                body: data,
            }),
        }),
        registerOrganization: builder.mutation<
            any,
            IUserRegisterWithOrganization
        >({
            query: (data) => ({
                url: '/authentication/sign-up/organization',
                method: 'POST',
                body: data,
            }),
        }),
        register: builder.mutation<any, ISignUp>({
            query: (data) => ({
                url: '/authentication/sign-up',
                method: 'POST',
                body: data,
            }),
        }),
        changePassword: builder.mutation<any, { userId: number }>({
            query: (data) => ({
                url: '/authentication/change-password',
                method: 'POST',
                body: data,
            }),
        }),

        // TODO исправить
        edit: builder.mutation<IUserWithDescription, IEditUserDto>({
            query: (data) => ({
                url: '/auth/edit',
                method: 'POST',
                body: data,
            }),
        }),
        logout: builder.mutation<any, void>({
            query: () => ({
                url: '/authentication/sign-out',
                method: 'POST',
            }),
        }),
    }),
    // login: builder.mutation<IUserResponseToken, IUserLogin>({
    //     query: (userData) => ({
    //         url: "/auth/login",
    //         method: "POST",
    //         body: userData,
    //     }),
    // }),
    // register: builder.mutation<IUserResponseToken, IUserRegister>({
    //     query: (userData) => {
    //         return {
    //             url: "/auth/registration",
    //             method: "POST",
    //             body: userData,
    //         };
    //     },
    // }),
    // check: builder.query<IUserResponseToken, void>({
    //     query: () => ({
    //         url: "/auth/check",
    //         method: "GET",
    //     }),
    // }),
    // edit: builder.mutation<IUserWithDescription, IEditUserDto>({
    //     query: (data) => ({
    //         url: "/auth/edit",
    //         method: "POST",
    //         body: data,
    //     }),
    // }),
});
